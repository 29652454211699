import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/business/marketing',
    component: Layout,
    redirect: '/business/marketing/coupon/index',
    meta: {
      title: '营销管理',
      keepAlive: true,
      requiresAuth: true
    },
    children: [
      // {
      //   path: 'coupon/index',
      //   name: 'BusinessMarketingCouponIndex',
      //   component: () => import('@/views/business/marketing/coupon/index.vue'),
      //   meta: {
      //     keepAlive: true,
      //     requiresAuth: true,
      //     title: '卡券管理'
      //   }
      // },
      {
        path: 'base/index',
        name: 'BusinessMarketingBaseIndex',
        component: () => import('@/views/business/marketing/base/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '基础配置'
        }
      },
      {
        path: 'rechargeActivity/index',
        name: 'BusinessMarketingActivityIndex',
        component: () => import('@/views/business/marketing/rechargeActivity/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '充值活动管理'
        }
      },
      {
        path: 'couponManage/index',
        name: 'BusinessMarketingCouponManageIndex',
        component: () => import('@/views/business/marketing/couponManage/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '卡券管理'
        }
      },
      {
        path: 'couponRecord/index',
        name: 'BusinessMarketingCouponRecordIndex',
        component: () => import('@/views/business/marketing/couponRecord/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '用户卡券记录'
        }
      },
      {
        path: 'lotteryManage/index',
        name: 'BusinessMarketingLotteryIndex',
        component: () => import('@/views/business/marketing/lotteryManage/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '奖品管理'
        }
      },
      {
        path: 'couponActivity/index',
        name: 'BusinessMarketingActivityCouponIndex',
        component: () => import('@/views/business/marketing/couponActivity/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '卡券活动管理'
        }
      },
      {
        path: 'lotteryActivity/index',
        name: 'BusinessMarketingLotteryActivityIndex',
        component: () => import('@/views/business/marketing/lotteryActivity/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '抽奖活动管理'
        }
      },
      {
        path: 'rebatesActivity/index',
        name: 'BusinessMarketingRebatesActivityIndex',
        component: () => import('@/views/business/marketing/rebatesActivity/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '返利活动管理'
        }
      },
      {
        path: 'homeCouponActivity/index',
        name: 'BusinessMarketingHomeCouponActivityIndex',
        component: () => import('@/views/business/marketing/homeCouponActivity/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '首页活动管理'
        }
      }
    ]
  }
]

export default router
